<template>
    <div class="g-wrapper">
		<button class="g-top" @click="toTop">
			<span class="g-top__text">Top</span>
            <span class="g-top__arrow">
                <i class="fa-solid fa-arrow-up-long"></i>
            </span>
		</button>
        <section class="g-main">
            <div class="g-sticky">
              <div class="g-blocks g-blocks--3 g-blocks--shrink">
                  <CostPerClickBlock></CostPerClickBlock>
                  <CTRBlock></CTRBlock>
                  <TotalCostBlock></TotalCostBlock>
                  <ConversionBlock></ConversionBlock>
                  <ConversionRateBlock></ConversionRateBlock>
                  <CostConversionBlock></CostConversionBlock>
              </div>
              <div class="g-table">
                  <div class="g-table__header">
                      <h3>All Data</h3>
                  </div>
                  <table class="g-table__table" cellspacing="0">
                      <thead>
                          <tr>
                              <th scope="col" colspan="6"></th>
                              <th scope="col" colspan="3">Conversions</th>
                              <th scope="col"></th>
                          </tr>
                          <tr>
                              <th width="17.11%" scope="col">Campaign</th>
                              <th width=" 7.42%" scope="col">Clicks</th>
                              <th width="11.70%" scope="col">Impressions</th>
                              <th width=" 7.42%" scope="col"><abbr title="Click Through Rate">CTR</abbr></th>
                              <th width="10.06%" scope="col"><abbr title="Average Cost Per Click">AVG CPC</abbr></th>
                              <th width=" 9.43%" scope="col">Cost</th>
                              <th width=" 9.06%" scope="col">Number</th>
                              <th width=" 7.55%" scope="col">Rate</th>
                              <th width=" 9.56%" scope="col">Cost per</th>
                              <th width="10.69%" scope="col"><abbr title="Search Impression Share">Search Impr.</abbr></th>
                          </tr>
                      </thead>
                  </table>
              </div>
            </div>
            <div class="g-table">
                <table class="g-table__table" cellspacing="0">
                    <AllDataBlock></AllDataBlock>
                </table>
            </div>
        </section>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import CostPerClickBlock from "../../components/ppc/CostPerClickBlock.vue"
import ConversionBlock from "../../components/ppc/ConversionBlock.vue"
import ConversionRateBlock from "../../components/ppc/ConversionRateBlock.vue"
import CostConversionBlock from "../../components/ppc/CostConversionBlock.vue"
import CTRBlock from "../../components/ppc/CTRBlock.vue"
import TotalCostBlock from "../../components/ppc/TotalCostBlock.vue"
import AllDataBlock from "../../components/ppc/AllDataBlock.vue"

export default {
    data() {
        return {
            hasToken: false
        }
    },
    components: {
        CostPerClickBlock,
        ConversionBlock,
        ConversionRateBlock,
        CostConversionBlock,
        CTRBlock,
        TotalCostBlock,
        AllDataBlock,
    },
    computed: {
        clientPagesLoaded: {
            get() {
				return this.$store.getters["client/getClientPagesLoaded"] || ""
            },
		},
        ...mapGetters("client", ["getClientSlug"]),
    },
    methods: {
        handleScroll() {
            // Any code to be executed when the window is scrolled
            this.isUserScrolling = (window.scrollY > 0);
            if ( window.scrollY > 30 ) {
                document.querySelector(".g-wrapper").classList.add('has-scrolled');
            } else {
                document.querySelector(".g-wrapper").classList.remove('has-scrolled');
            }
        },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
    watch: {
        'clientPagesLoaded.ppcComponents': {
            
            handler: function(newValue) {
                console.log('comp-loaded:', newValue);
                if (Object.values(newValue).every(val => val === true)) {

                    this.$store.commit('client/setHidePageLoader', true);

                }else{

                    this.$store.commit('client/setHidePageLoader', false);

                }

            },

            deep: true
        },
    },
    // beforeUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // },
    // mounted() {

    //     window.addEventListener('scroll', this.handleScroll);
    // },
}
</script>

<style lang="scss">
@import "./src/assets/scss/views/google.scss";
</style>
