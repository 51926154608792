<template>
  <tbody>
      <tr v-for="(row) in getComponents.ppc.AllDataBlock.tableRows" :key="row">
          <th width="17.11%" scope="row"><div v-html="row[0]"></div></th>
          <td width=" 7.42%"><div v-html="row[1]"></div></td>
          <td width="11.70%"><div v-html="row[2]"></div></td>
          <td width=" 7.42%"><div v-html="row[3]"></div></td>
          <td width="10.06%"><div v-html="row[4]"></div></td>
          <td width=" 9.43%"><div v-html="row[5]"></div></td>
          <td width=" 9.06%"><div v-html="row[6]"></div></td>
          <td width=" 7.55%"><div v-html="row[7]"></div></td>
          <td width=" 9.56%"><div v-html="row[8]"></div></td>
          <td width="10.69%"><div v-html="row[9]"></div></td>
      </tr>
  </tbody>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  computed: {
        ...mapGetters("client", ["getComponents", "getSocial"]),
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            handler(newValue) {

                this.init(newValue);

            }
        }
    },
    methods:{
        async init(data){
            try{
                const GA4PropertyID = data.GA4PropertyID;
                const propertyID = data.accountID;
                const accessToken = data.accessToken;
                const adsID = data.adsAccountID;

                if(accessToken && GA4PropertyID && propertyID && adsID){

                    await this.$store.dispatch('client/ppcAllDataBlockInit');
                    this.componentHasAllData();

                }
            }catch(error){
                console.log('init error', error);
            }
        },
        componentHasAllData(){

          let newVal = {
            vue: 'ppcComponents',
            component: 'AllDataBlock',
            val: true
          }

          this.$store.commit('client/setPageLoaded', newVal);

        },
    },
}

</script>
