<template>
	<div class="g-block">
		<div class="conversion-rate conversion-rate--large" v-bind:class="getComponents.ppc.ConversionBlock.conversionClass">
			<div class="grid-container">
				<div class="col"><p>{{ formatPercentage(getComponents.ppc.ConversionBlock.conversionComparison) }}</p></div>
				<div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
			</div>
		</div>
		<h3>Conversion</h3>
		<span class="g-block__num">{{ getComponents.ppc.ConversionBlock.conversionVal }}</span>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {

  computed: {
        ...mapGetters("client", ["getComponents", "getSocial"]),
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            handler(newValue) {

                this.init(newValue);

            }
        }
    },
    methods:{
        async init(data){
            try{
                const GA4PropertyID = data.GA4PropertyID;
                const propertyID = data.accountID;
                const accessToken = data.accessToken;
                const adsID = data.adsAccountID;

                if(accessToken && GA4PropertyID && propertyID && adsID){

                    await this.$store.dispatch('client/ppcConversionBlockInit');
                    this.componentHasAllData();

                }
            }catch(error){
                console.log('init error', error);
            }
        },
        componentHasAllData(){

          let newVal = {
            vue: 'ppcComponents',
            component: 'ConversionBlock',
            val: true
          }

          this.$store.commit('client/setPageLoaded', newVal);

        },
    },
}

</script>
